<template>
    <div id="loader" v-if="!hide" :class="show? 'show' : 'hide'" :style="{opacity: opacity}" ref="banner">
        <div class="center">
            <div class="logo" v-if="img != null">
                <img :src="img" alt="Kristal Design logo">
            </div>
            <div class="text">
                <span>Een momentje!</span>
            </div>
        </div>
    </div>
</template>
<script>
import Symbol from "@/assets/images/symbol.png"

export default {
    name: "Loader",
    props: ['show'],
    data: () => ({
        hide: false,
        img: Symbol,
        opacity: 1
    }),
    created() {
        this.opacity = 1
    },
    watch: {
        show() {
            if (!this.show) {
                this.opacity = 0
            } else {
                this.opacity = 1
                this.hide = false
            }
        },
        opacity() {
            this.hide = this.opacity === 0;
        },
    }
}
</script>
<style lang="scss" scoped>
@import "src/assets/css/variables";

#loader {
    color: $black;
    background-color: $grey;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .2s;
    opacity: 1;

    &.hide {
        opacity: 0;
        z-index: 1;
    }

    & .center {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & .logo {
            & img {
                max-width: 100px;
                max-height: 100px;
                -webkit-animation: spin 2s infinite normal;
                -moz-animation: spin 2s infinite normal;
                animation: spin 2s infinite normal;
            }
        }

        & .text {
            margin-top: 10px;
        }
    }
}
</style>
