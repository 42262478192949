<template>
    <div id="app">
        <Loader :show="!loaded" />
        <MainNavigation :title="title" />
        <router-view :resources="resources" @loaded="doLoad" />
        <cookie-wall v-if="cookies == null" />
        <FooterSection :title="title" />
    </div>
</template>
<script>
import Loader from "@/components/Loader";
import MainNavigation from "@/components/sections/NavigationSection";
import FooterSection from "@/components/sections/FooterSection";
import CookieWall from "@/components/CookieWall";

export default {
    name: "App",
    components: {
        CookieWall,
        FooterSection,
        MainNavigation,
        Loader
    },
    data() {
        return {
            loaded: false
        }
    },
    metaInfo() {
        return {
            titleTemplate: `%s | ${this.title}`,
            meta: [
                {name: 'url', content: location.host},
                {name: 'og:url', content: location.host},
            ]
        }
    },
    beforeCreate() {
        this.$store.dispatch('setCookies', null)
        this.$store.dispatch('loadResources')
        this.loaded = false
    },
    computed: {
        resources() {
            return this.$store.getters.resources ?? null
        },
        title() {
            return this.resources?.title ?? "Kristal Design"
        },
        cookies() {
            return this.$store.getters.cookies
        }
    },
    methods: {
        doLoad(load) {
            this.loaded = load ?? true
        }
    }
}

export function absolute(path = null) {
    if (path == null) {
        return null
    }
    return `https://kristaldesign.nl/${path}`.replace('//cockpit', '/cockpit')
}
</script>
