<template>
    <footer id="contact" :style="{backgroundImage: `url('${leaf}')`}" class="illustration">
        <div class="container">
            <div class="row">
                <div class="bottom">
                    <div class="branding links">
                        <ul>
                            <li><span class="h2">Contact</span></li>
                            <li><span>Kristel Janssen</span></li>
                            <li><a href="mailto:info@kristaldesign.nl">info@kristaldesign.nl</a></li>
                            <li><i>Graphic designer</i></li>
                            <li><i>Video editor</i></li>
                        </ul>
                    </div>
                    <div class="socials">
                    </div>
                    <div class="socials links">
                        <ul>
                            <li>
                                <span class="h2">Socials</span>
                            </li>
                            <li v-for="(link, social) in urls" v-if="link != null" :key="social">
                                <a :href="link == null? '#' : link" :target="link == null? '_window' : '_blank'" rel="noopener">{{ social }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="alt-links links">
                        <ul>
                            <li>
                                <span class="h2">Links</span>
                            </li>
                            <li>
                                <router-link to="/algemene-voorwaarden">Algemene voorwaarde</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="copyright">
                    <p>Copyright
                        {{ new Date().getFullYear() }} ~
                        <router-link to="/" class="special">
                            Kristal Design
                        </router-link>
                    </p>
                    <p class="credits">Made with <span class="love">☕</span> by <a href="https://www.robinvanuden.com">@robinvanudenn</a></p>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import Leaf from "@/assets/images/leaf.png";

export default {
    name: "FooterSection",
    props: ['image', 'title'],
    data() {
        return {
            leaf: Leaf
        }
    },
    created() {
        this.$store.dispatch('loadSocials')
    },
    computed: {
        urls() {
            return this.$store.getters.socials ?? null
        }
    }
}
</script>
<style lang="scss" scoped>
@import "src/assets/css/sections/Footer";
</style>
