<template>
    <div id="cookie-wall">
        <div class="text">
            <p>Deze website maakt gebruik van cookies om media te laten zien. Voor meer informatie lees de
                <router-link to="/algemene-voorwaarden">Algemene voorwaarden</router-link>
            </p>
        </div>
        <div class="actions">
            <button class="link" @click="accept">Accepteren</button>
            <button class="link" @click="deny">Weigeren</button>
        </div>
    </div>
</template>
<script>
export default {
    name: "CookieWall",
    methods: {
        accept() {
            this.$store.dispatch('setCookies', true)
        },
        deny() {
            this.$store.dispatch('setCookies', false)
        }
    }
}
</script>
<style lang="scss" scoped>
@import "src/assets/css/variables";

#cookie-wall {
    background-color: $black;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    @media (min-width: 768px) {
        flex-direction: row;
    }

    & .text {

        & p {
            color: $white;
            font-size: 16px;
            padding: 20px;
            margin: 0;
        }
    }
    & .actions {
        display: flex;
        align-items: center;
        & button {
            color: $white;
            background-color: $primary;
            border: 0;
            border-radius: 0;
            line-height: 35px;
            margin-left: 20px;
            display: block;

            &:nth-child(even){
                background-color: transparent;
                color: $primary;
            }
        }
    }
}
</style>
