import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

axios.defaults.baseURL = process.env.apiUrl || "https://kristaldesign.nl/api"
Vue.prototype.$axios = axios;

const COOKIE_KEY = 'accepted-cookies'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        navigation: [],
        portfolio: [],
        page: {},
        article: {},
        socials: {},
        resources: {},
        cookies: null
    },
    getters: {
        navigation: state => {
            return state.navigation;
        },
        portfolio: state => {
            return state.portfolio;
        },
        page: state => {
            return state.page;
        },
        article: state => {
            return state.article;
        },
        resources: state => {
            return state.resources;
        },
        socials: state => {
            let obj = {};
            for (const key in state.socials) {
                const value = state.socials[key]
                if (key.indexOf('_') === -1 && value != null) {
                    obj[key] = value
                }
            }
            return obj
        },
        cookies: state => {
            return state.cookies
        }
    },
    mutations: {
        SET_NAVIGATION(state, navigation) {
            state.navigation = navigation
        },
        SET_PORTFOLIO(state, portfolio) {
            state.portfolio = portfolio
        },
        SET_PAGE(state, page) {
            state.page = page
        },
        SET_ARTICLE(state, article) {
            state.article = article
        },
        SET_SOCIALS(state, socials) {
            state.socials = socials
        },
        SET_RESOURCES(state, resources) {
            state.resources = resources
        },
        SET_COOKIES(state, boolean) {
            state.cookies = boolean
        }
    },
    actions: {
        async loadNavigation({commit}) {
            try {
                const response = await axios.get('pages')
                // JSON responses are automatically parsed.
                commit('SET_NAVIGATION', response.data)
            } catch (error) {
                console.log(error);
                commit('SET_NAVIGATION', null)
            }
        },
        async loadPortfolio({commit}) {
            try {
                const response = await axios.get('portfolio')
                // JSON responses are automatically parsed.
                commit('SET_PORTFOLIO', response.data)
            } catch (error) {
                console.log(error);
                commit('SET_PORTFOLIO', null)
            }
        },
        async loadSocials({commit}) {
            try {
                const response = await axios.get('single/socials')
                // JSON responses are automatically parsed.
                commit('SET_SOCIALS', response.data)
            } catch (error) {
                console.log(error);
                commit('SET_SOCIALS', null)
            }
        },
        async loadResources({commit}) {
            try {
                const response = await axios.get('single/global')
                // JSON responses are automatically parsed.
                commit('SET_RESOURCES', response.data)
            } catch (error) {
                console.log(error);
                commit('SET_RESOURCES', null)
            }
        },
        async loadPage({commit}, path) {
            try {
                const response = await axios.get(`pages/${path}`)
                // JSON responses are automatically parsed.
                commit('SET_PAGE', response.data)
            } catch (error) {
                console.log(error);
                commit('SET_PAGE', null)
            }
        },
        async loadArticle({commit}, path) {
            try {
                const response = await axios.get(`portfolio/${path}`)
                // JSON responses are automatically parsed.
                commit('SET_ARTICLE', response.data)
            } catch (error) {
                commit('SET_ARTICLE', null)
            }
        },
        setCookies({commit}, bool = null) {
            if (bool != null) {
                switch (bool){
                    case true:
                    case 'true':
                        Vue.cookie.set(COOKIE_KEY, true)
                        commit("SET_COOKIES", true)
                        break;
                    case false:
                    case 'false':
                        Vue.cookie.set(COOKIE_KEY, false)
                        commit("SET_COOKIES", false)
                        break;
                    case null:
                    case 'null':
                        Vue.cookie.set(COOKIE_KEY, null)
                        commit("SET_COOKIES", null)
                        break;
                }
            } else {
                const accepted = Vue.cookie.get(COOKIE_KEY);
                switch (accepted){
                    case true:
                    case 'true':
                        commit("SET_COOKIES", true)
                        break;
                    case false:
                    case 'false':
                        commit("SET_COOKIES", false)
                        break;
                    case null:
                    case 'null':
                        commit("SET_COOKIES", null)
                        break;
                }
            }
        }
    }
})

