import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'


Vue.use(VueRouter)
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  refreshOnceOnNavigation: true
})

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: () => import("../views/PageView")
  },
  {
    path: '/:page',
    name: 'Page',
    component: () => import("../views/PageView")
  },
  {
    path: '/portfolio/:item',
    name: 'Article',
    component: () => import("../views/ArticleView")
  },
  {
    path: '*',
    name: '404 Not found',
    component: () => import("../views/UnknownView")
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
