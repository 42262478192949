import Vue from 'vue'
import VueCookie from 'vue-cookie'
Vue.use(VueCookie)
import store from './store'
import router from './router'

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(fas)
library.add(far)
library.add(fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import App from './App.vue'
import "@/assets/css/master.scss"

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
