<template>
    <header>
        <div id="navigation">
            <nav :class="show?'show':'hide'">
                <ul>
                    <li>
                        <router-link to="/" @click.native="show = false">
                            <img :src="logo_image" :alt="title">
                        </router-link>
                    </li>
                    <NavigationLink v-for="(page, p) in pages" @click.native="show = false" :key="p" :page="page" />
                </ul>
            </nav>
            <div class="mobile-button" :class="show?'cross':'bars'" @click="show = !show">
                <span></span><span></span><span></span>
            </div>
        </div>
    </header>
</template>
<script>
import NavigationLink from "@/components/NavigationLink";
import Logo from "@/assets/images/logo-alt.png"

export default {
    name: "NavigationSection",
    components: {NavigationLink},
    props: ['title'],
    data: () => ({
        show: false,
        logo_image: Logo
    }),
    created() {
        this.$store.dispatch('loadNavigation')
    },
    computed: {
        pages() {
            return this.$store.getters.navigation ?? []
        }
    }
}
</script>
<style lang="scss" scoped>
@import "src/assets/css/sections/Navigation";
</style>
