<template>
    <li v-if="in_menu && !root">
        <a v-if="direct_link !== ''" :href="link"></a>
        <router-link :to="link">{{ title }}</router-link>
    </li>
</template>
<script>

export default {
        name: "NavigationLink",
        props: ['page'],
        computed: {
            title(){
                return this.page?.title ?? ""
            },
            slug(){
                return this.page?.title_slug ?? ""
            },
            direct_link(){
                return this.page?.direct_link ?? ""
            },
            link(){
                if (this.direct_link !== ""){
                    return this.direct_link
                }
                if (this.root){
                    return "/"
                }
                return `/${this.slug}`
            },
            in_menu(){
                return this.page?.navigation ?? false
            },
            root(){
                return this.page?.root ?? false
            }
        }
    }
</script>
<style scoped>
</style>
